import { Controller } from "@hotwired/stimulus"

/*
 * Clipboard Copy
 * ==========
 *
 * Copy an anchor link or input value to the clipboard
 *
 * Actions:
 *  - copy
 *
 * Target:
 *  - source : element to copy the value from
 *
 * Example:
 *  <div data-controller="clipboard-copy">
 *    <button data-action="click->clipboard-copy#copy">
 *     copy
 *    </button>
 *    <input type="hidden" data-clipboard-copy-target="source" value="12345">
 *  </div>
 */
export default class ClipboardCopyController extends Controller {
  declare readonly sourceTarget: HTMLElement
  static targets = ["source"]

  get valueToCopy() {
    if (
      this.sourceTarget instanceof HTMLInputElement ||
      this.sourceTarget instanceof HTMLTextAreaElement
    ) {
      return this.sourceTarget.value
    } else if (this.sourceTarget instanceof HTMLAnchorElement) {
      return this.sourceTarget.href
    }

    throw "Unknown element type to copy from, should be an anchor or input!"
  }

  copy(event: MouseEvent) {
    event.preventDefault()

    navigator.clipboard.writeText(this.valueToCopy)
  }
}
