import { Controller } from "@hotwired/stimulus"

enum Theme {
  Dark = "dark",
  Light = "light",
}

const STORAGE_KEY = "theme"

/*
 * Darkmode Toggle
 * ==========
 *
 * Attempts to restore the users prefered darkmode setting from localstorage,
 * falling back to the user-agents prefered color scheme if unavailable.
 *
 * Adds a `dark` class to the html element if darkmode is active, and attempts to set
 * the `active` value for any child `Toggler` instances.
 *
 * Actions:
 *  - flipLightSwitch
 *
 * Example:
 *  <button data-controller="darkmode-toggle" data-action="click->darkmode-toggle#flipLightSwitch">
 *    <span data-toggler-target="toggle" data-toggler-active-class="red" data-toggler-inactive-class="blue">
 *  </button>
 */
export default class DarkmodeToggleController extends Controller {
  watcher: MediaQueryList

  connect() {
    this.watcher = window.matchMedia("(prefers-color-scheme: dark)")
    this.watcher.addEventListener("change", this.applyTheme)

    this.applyTheme()
  }

  disconnect() {
    this.watcher.removeEventListener("change", this.applyTheme)
  }

  prefersDarkTheme = () => this.watcher.matches

  applyTheme = () => {
    const preferredTheme = this.prefersDarkTheme() ? Theme.Dark : Theme.Light
    const currentTheme = localStorage.getItem(STORAGE_KEY) ?? preferredTheme

    const isDark = currentTheme === Theme.Dark
    document.querySelector("html").classList.toggle("dark", isDark)

    this.element
      .querySelectorAll("[data-controller~=toggler]")
      .forEach(target =>
        target.setAttribute("data-toggler-active-value", String(isDark))
      )
  }

  flipLightSwitch(event: MouseEvent) {
    event.preventDefault()

    const stored = localStorage.getItem(STORAGE_KEY)

    if (stored) localStorage.removeItem(STORAGE_KEY)
    else
      localStorage.setItem(
        STORAGE_KEY,
        this.prefersDarkTheme() ? Theme.Light : Theme.Dark
      )

    this.applyTheme()
  }
}
