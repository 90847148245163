import * as Turbo from "@hotwired/turbo"
import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"

Turbo.start()

const application = Application.start()
application.debug = true
window.application = application

export const controllers = import.meta.globEager('../controllers/**/*_controller.*')
registerControllers(application, controllers)

function uploadPreviewComponent() {
  return {
    expanded: false,
    hasImage: false,
    dragging: false,
    updatePreview() {
      let file = this.$refs.input.files[0]
      let imageTag = this.$refs.image

      let reader = new FileReader()
      reader.onload = (e) => {
        imageTag.src = e.target.result
      }

      reader.readAsDataURL(file)

      this.hasImage = true
    },
    drop: {
      ["@drop.stop.prevent"](event) {
        this.$refs.input.files = event.dataTransfer.files
        this.updatePreview()
      },
      ["@drag.stop.prevent"]() {},
      ["@dragstart.stop.prevent"]() {},
      ["@dragover.stop.prevent"]() {
        this.dragging = true
      },
      ["@dragenter.stop.prevent"]() {
        this.dragging = true
      },
      ["@dragleave.stop.prevent"]() {
        this.dragging = false
      },
      ["@dragend.stop.prevent"]() {
        this.dragging = false
      },
      ["@paste.window"](event) {
        if (event.clipboardData.files.length == 0) return

        event.preventDefault()
        event.stopPropagation()

        this.$refs.input.files = event.clipboardData.files
        this.updatePreview()
      }
    }
  }
}

window.uploadPreviewComponent = uploadPreviewComponent
