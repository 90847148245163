import { Controller } from "@hotwired/stimulus"

import Tagify from "@yaireo/tagify"

/*
 * Tagify
 * ==========
 *
 * Hooks up an input to Tagify
 *
 * Values:
 *  - allowlist
 *
 * Target:
 *  - input
 *
 * Example:
 *  <div data-controller="tagify" data-tagify-allow-list="one,two,three">
 *    <input data-tagify-target="input">
 *  </button>
 */
export default class TagifyController extends Controller {
  declare allowlistValue: string
  static values = {
    allowlist: String,
  }

  declare readonly inputTarget: HTMLInputElement
  static targets = ["input"]

  tagify: InstanceType<typeof Tagify>

  connect() {
    let opts: Record<string, any> = {
      originalInputValueFormat: valuesArr =>
        valuesArr.map(item => item.value).join(","),
      pattern: /^[A-Za-z1-9_-\s\/]+$/,
      editTags: false,
    }

    if (this.allowlistValue)
      opts.whitelist = (this.allowlistValue || "").split(",")

    console.log(opts)

    this.tagify = new Tagify(this.inputTarget, opts)
    console.debug(this.tagify)
  }

  disconnect() {
    this.tagify.destroy()
  }
}
