import { Controller } from "@hotwired/stimulus"

import { DateTime } from "luxon"

/*
 * Locale Time
 * ==========
 *
 * Translate a time tags RFC3339 formated timestamp into a
 * timezone and locale aware human format
 *
 * Example:
 *  <time data-controller="locale-time" datetime="<%= rfc3339 %>">
 *    <%= human_fallback %>
 *   </time>
 */
export default class LocaleTimeController extends Controller {
  connect() {
    const datetime = this.element.attributes.getNamedItem("datetime").value
    const utc = DateTime.fromISO(datetime)

    this.#asHtmlElem.innerText = utc.toLocaleString(DateTime.DATETIME_MED)
  }

  get #asHtmlElem() {
    return this.element as HTMLElement
  }
}
