import { Controller } from "@hotwired/stimulus"

export default class FlashController extends Controller {
  declare countdownValue: number
  declare durationValue: string
  static values = {
    duration: String,
    countdown: Number,
  }

  declare readonly circleTarget: SVGCircleElement
  static targets = ["circle"]

  timer: number
  circumference: number

  connect() {
    if (this.durationValue === "permanent") return

    this.countdownValue = parseInt(this.durationValue)

    this.timer = setInterval(() => this.countDown(), 1000)

    let radius = this.circleTarget.r.baseVal.value
    this.circumference = radius * 2 * Math.PI

    this.circleTarget.style.strokeDasharray = `${this.circumference} ${this.circumference}`
    this.circleTarget.style.strokeDashoffset = `${this.circumference}`
  }

  countDown() {
    this.countdownValue--

    let offset =
      this.circumference -
      (this.countdownValue / parseInt(this.durationValue)) * this.circumference

    this.circleTarget.style.strokeDashoffset = `${offset}`

    if (this.countdownValue == 0) return this.close()
  }

  close() {
    clearInterval(this.timer)
    this.element.remove()
  }
}
