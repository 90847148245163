import { Controller } from "@hotwired/stimulus"

/*
 * Toggler
 * ==========
 *
 * Toggles CSS classes on targets according to the `active` value
 *
 * Each target can define a data-toggler-active-class and data-toggler-inactive-class
 * When active, each target will have it's active-class dataset _added_\
 * all inactive-class dataset _removed_. When inactive, this process is reversed.
 *
 * Actions:
 *  - toggle
 *  - forceActive
 *  - forceInactive
 *
 * Targets:
 *  - toggle : element to toggle the classes of
 *
 * Values:
 *  - active (Boolean)
 *
 * Example:
 *  <button data-controller="toggler" data-action="click->toggler#toggle">
 *    <span data-toggler-target="toggle" data-toggler-active-class="red" data-toggler-inactive-class="blue">
 *  </button>
 */
export default class TogglerController extends Controller {
  declare activeValue: boolean
  static values = { active: Boolean }

  declare readonly toggleTargets: HTMLElement[]
  static targets = ["toggle"]

  activeValueChanged() {
    const activeMethod = this.activeValue ? "add" : "remove"
    const inactiveMethod = this.activeValue ? "remove" : "add"

    this.toggleTargets.forEach(target => {
      const {
        togglerActiveClass: activeClasses,
        togglerInactiveClass: inactiveClasses,
      } = target.dataset

      if (activeClasses)
        target.classList[activeMethod](...activeClasses.split(" "))

      if (inactiveClasses)
        target.classList[inactiveMethod](...inactiveClasses.split(" "))
    })
  }

  toggle = () => (this.activeValue = !this.activeValue)
  forceActive = () => (this.activeValue = true)
  forceInactive = () => (this.activeValue = false)
}
