import { Controller } from "@hotwired/stimulus"

import { DateTime } from "luxon"

/*
 * Relative Time Input
 * ==========
 *
 * Converts a UTC date input into a timezone aware input
 *
 * Target:
 *  - input
 *
 * Actions:
 *  - updateUtcTime
 *
 * Example:
 *  <div data-controller="relative-time-input">
 *    <input type="date" data-action="relative-time-input#updateUtcTime" data-relative-time-input-target="input">
 *  </button>
 */
export default class RelativeTimeInputController extends Controller {
  utcInput: HTMLInputElement

  declare readonly inputTarget: HTMLInputElement
  static targets = ["input"]

  connect() {
    this.utcInput = this.inputTarget.cloneNode() as HTMLInputElement
    this.utcInput.type = "hidden"
    this.utcInput.id = this.utcInput.id + "_utc"
    this.utcInput.dataset.action = null

    this.inputTarget.name = ""
    this.element.insertBefore(this.utcInput, this.inputTarget)
  }

  updateUtcTime(event: InputEvent) {
    let local = (event.target as HTMLInputElement).value
    let utc = DateTime.fromISO(local).toUTC().toISODate()

    this.utcInput.value = utc
  }
}
