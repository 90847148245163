import { Controller } from "@hotwired/stimulus"

export default class FilePasterController extends Controller {
  declare readonly inputTarget: HTMLInputElement
  static targets = ["input"]

  connect() {
    document.addEventListener("paste", this.onPaste)
  }

  disconnect() {
    document.removeEventListener("paste", this.onPaste)
  }

  onPaste(event: ClipboardEvent) {
    if (event.clipboardData.files.length == 0) return

    event.preventDefault()
    event.stopPropagation()

    this.inputTarget.files = event.clipboardData.files
  }
}
