import { Controller } from "@hotwired/stimulus"

export default class FilePreviewController extends Controller {
  declare readonly inputTarget: HTMLInputElement
  declare readonly imgTarget: HTMLImageElement
  static targets = ["input", "img"]

  connect() {}

  updatePreview() {
    let file = this.inputTarget.files[0]

    let reader = new FileReader()
    reader.onload = e => {
      this.imgTarget.src = e.target.result.toString()
    }

    reader.readAsDataURL(file)
  }
}
