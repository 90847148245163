import { Controller } from "@hotwired/stimulus"

export default class DragAndDropController extends Controller {
  declare readonly inputTarget: HTMLInputElement
  static targets = ["input"]

  onDrop(event: DragEvent) {
    this.inputTarget.files = event.dataTransfer.files
  }

  startDragging = (event: DragEvent) => {}

  stopDragging = (event: DragEvent) => {}

  onDrag = (event: DragEvent) => {}
}
